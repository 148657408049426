import * as Types from '../../../generated/graphql';

import { ShortConferenceDataFragment, FormDataFragment, FormFieldDataFragment, AbstractDisplayerDataFragment, ClientBaseDataFragment, ChargeDataFragment } from '../../data/fragments.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ShortConferenceDataFragmentDoc, FormDataFragmentDoc, FormFieldDataFragmentDoc, AbstractDisplayerDataFragmentDoc, ClientBaseDataFragmentDoc, ChargeDataFragmentDoc } from '../../data/fragments.generated';
export type GetShortConferenceQueryVariables = Types.Exact<{
  shortName: Types.Scalars['String'];
}>;


export type GetShortConferenceQuery = (
  { __typename?: 'Query' }
  & { conference?: Types.Maybe<(
    { __typename?: 'Conference' }
    & ShortConferenceDataFragment
  )> }
);

export type CurrentFormQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
  formName: Types.Scalars['String'];
}>;


export type CurrentFormQuery = (
  { __typename?: 'Query' }
  & { forms: Array<(
    { __typename?: 'Form' }
    & FormDataFragment
  )> }
);


export const GetShortConferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetShortConference"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"shortName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"shortName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShortConferenceData"}}]}}]}},...ShortConferenceDataFragmentDoc.definitions]} as unknown as DocumentNode<GetShortConferenceQuery, GetShortConferenceQueryVariables>;
export const CurrentFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"formName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortNameEq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"formName"}}},{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormData"}}]}}]}},...FormDataFragmentDoc.definitions]} as unknown as DocumentNode<CurrentFormQuery, CurrentFormQueryVariables>;