import { FormSettings } from '../../data/interfaces/FormSettings'
import { useQuery } from '@apollo/client/react/hooks'
import { CurrentFormDocument, GetShortConferenceDocument } from '../layout/AppContext.generated'
import { ConferenceSettings } from '../../data/interfaces/ConferenceSettings'
import { useCurrentQuery } from '../layout/AppContext'

export const useCurrentFormNameAllowEmpty = () => {
  const { formName } = useCurrentQuery()
  if (Array.isArray(formName)) return
  return formName
}
export const useCurrentFormName = (): string => {
  const formName = useCurrentFormNameAllowEmpty()
  if (!formName) {
    throw new Error('Form name not found')
  }
  return formName
}
export const useCurrentFormNameParams = () => {
  return { conferenceName: useCurrentConferenceName(), formName: useCurrentFormName() }
}
export const useCurrentForm = () => {
  const conferenceName = useCurrentConferenceName()
  const formName = useCurrentFormName()

  const { data, loading } = useQuery(CurrentFormDocument, {
    variables: {
      conferenceName,
      formName,
    },
  })
  if (!data) {
    return
  }

  return data.forms[0]
}
export const useCurrentConferenceNameAllowEmpty = () => {
  const { conferenceName } = useCurrentQuery()
  if (Array.isArray(conferenceName)) {
    return
  }
  return conferenceName
}
export const useCurrentConferenceName = () => {
  const { conferenceName } = useCurrentQuery()
  if (!conferenceName || Array.isArray(conferenceName)) {
    throw new Error('ConferenceName not found')
  }
  return conferenceName
}

export function useCurrentConferenceQuery() {
  const shortName = useCurrentConferenceNameAllowEmpty()

  return useQuery(GetShortConferenceDocument, {
    skip: !shortName,
    variables: { shortName: shortName || '' },
  })
}

export const useCurrentConference = () => {
  const { data } = useCurrentConferenceQuery()
  if (!data) {
    return
  }

  return data.conference || undefined
}
export const useCurrentConferenceSettings = () => {
  const conference = useCurrentConference()
  if (!conference) return

  return conference.settings as ConferenceSettings
}
export const useCurrentFormSettings = () => {
  const form = useCurrentForm()
  if (!form) return

  return form.settings as FormSettings
}
