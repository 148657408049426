import * as Types from '../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PersonDataFragment = (
  { __typename?: 'Person' }
  & Pick<Types.Person, 'id' | 'data' | 'personType'>
);

export type TagsDataFragment = (
  { __typename?: 'Tag' }
  & Pick<Types.Tag, 'id' | 'name' | 'color'>
);

export type RegistrationDataFragment = (
  { __typename?: 'Registration' }
  & Pick<Types.Registration, 'id' | 'participantId' | 'createdAt'>
  & { form?: Types.Maybe<(
    { __typename?: 'Form' }
    & FormDataFragment
  )> }
);

export type ChargeDataFragment = (
  { __typename?: 'Charge' }
  & Pick<Types.Charge, 'id' | 'amount' | 'amountCents' | 'currency' | 'title' | 'type' | 'productType' | 'due' | 'clientId' | 'creditCents'>
);

export type EmailDataFragment = (
  { __typename?: 'Email' }
  & Pick<Types.Email, 'id' | 'to' | 'title' | 'html' | 'date'>
);

export type PaymentDataFragment = (
  { __typename?: 'Payment' }
  & Pick<Types.Payment, 'id' | 'amount' | 'currency' | 'data' | 'paymentState' | 'paymentType' | 'date'>
);

export type SelectedProductDataFragment = (
  { __typename?: 'SelectedProduct' }
  & Pick<Types.SelectedProduct, 'id'>
  & { formProduct?: Types.Maybe<(
    { __typename?: 'FormProduct' }
    & Pick<Types.FormProduct, 'id' | 'title' | 'type' | 'prices'>
  )>, productRegistrations: Array<(
    { __typename?: 'ProductRegistration' }
    & ProductRegDataFragment
  )>, charges: Array<(
    { __typename?: 'Charge' }
    & Pick<Types.Charge, 'regPeriod'>
    & { client?: Types.Maybe<(
      { __typename?: 'Client' }
      & Pick<Types.Client, 'id' | 'name'>
    )>, formProduct?: Types.Maybe<(
      { __typename?: 'FormProduct' }
      & Pick<Types.FormProduct, 'id' | 'title' | 'prices'>
    )> }
    & ChargeDataFragment
  )> }
);

export type ProductRegDataFragment = (
  { __typename?: 'ProductRegistration' }
  & Pick<Types.ProductRegistration, 'id' | 'amount' | 'data' | 'notes'>
  & { formProduct?: Types.Maybe<(
    { __typename?: 'FormProduct' }
    & Pick<Types.FormProduct, 'id' | 'title' | 'fields' | 'formId' | 'type'>
  )>, shareGroup: Array<(
    { __typename?: 'ProductRegistration' }
    & Pick<Types.ProductRegistration, 'id'>
    & { participant: (
      { __typename?: 'Participant' }
      & Pick<Types.Participant, 'id' | 'name'>
    ) }
  )>, charges: Array<(
    { __typename?: 'Charge' }
    & Pick<Types.Charge, 'id'>
  )>, product: (
    { __typename?: 'Product' }
    & CategoryDataFragment
  ), people: Array<(
    { __typename?: 'Person' }
    & PersonDataFragment
  )> }
);

export type InvoiceDataFragment = (
  { __typename?: 'Invoice' }
  & Pick<Types.Invoice, 'id' | 'number' | 'amountCents' | 'currency' | 'htmlUrl' | 'pdfUrl' | 'date' | 'invoiceType' | 'products'>
);

export type ParticipantCountsDataFragment = (
  { __typename?: 'ParticipantCount' }
  & Pick<Types.ParticipantCount, 'id' | 'reviewsAssigned' | 'reviewsCompleted' | 'submittedAbstracts' | 'totalCharges' | 'totalPayed' | 'totalDue'>
);

export type FormFieldDataFragment = (
  { __typename?: 'FormField' }
  & Pick<Types.FormField, 'id' | 'key' | 'displayType' | 'fieldType' | 'label' | 'order' | 'subFields'>
);

export type AbstractDisplayerDataFragment = (
  { __typename?: 'Abstract' }
  & Pick<Types.Abstract, 'id' | 'acceptedFor' | 'data' | 'submitterData' | 'title' | 'body' | 'category'>
  & { conference: (
    { __typename?: 'Conference' }
    & Pick<Types.Conference, 'id' | 'scienceSettings'>
  ), abstractGroup?: Types.Maybe<(
    { __typename?: 'AbstractGroup' }
    & Pick<Types.AbstractGroup, 'id' | 'title' | 'body'>
  )> }
);

export type ConferenceUserDataFragment = (
  { __typename?: 'ConferenceUser' }
  & Pick<Types.ConferenceUser, 'id' | 'participantSettings' | 'reviewerId' | 'scienceData' | 'name' | 'email' | 'roles' | 'hasPassword' | 'participantId' | 'registrationData' | 'hasDebt'>
  & { forms: Array<(
    { __typename?: 'PartialForm' }
    & Pick<Types.PartialForm, 'id'>
  )> }
);

export type ShortConferenceDataFragment = (
  { __typename?: 'Conference' }
  & Pick<Types.Conference, 'id' | 'name' | 'shortName' | 'settings' | 'scienceSettings'>
  & { forms: Array<(
    { __typename?: 'Form' }
    & Pick<Types.Form, 'id' | 'name' | 'shortName' | 'settings'>
  )> }
);

export type FormDataFragment = (
  { __typename?: 'Form' }
  & Pick<Types.Form, 'id' | 'conferenceId' | 'name' | 'shortName' | 'settings'>
);

export type ClientBaseDataFragment = (
  { __typename?: 'Client' }
  & Pick<Types.Client, 'id' | 'name'>
);

export type ClientShowDataFragment = (
  { __typename?: 'Client' }
  & Pick<Types.Client, 'email' | 'idNumber' | 'accountBookData' | 'settings'>
  & ClientBaseDataFragment
);

export type FormCategoryDataFragment = (
  { __typename?: 'FormProduct' }
  & Pick<Types.FormProduct, 'id' | 'description' | 'ord' | 'title' | 'prices' | 'data' | 'extraCharges' | 'type' | 'hasPartner' | 'fields' | 'showIf' | 'state' | 'modifierOnly' | 'participantCount'>
  & { formProductProducts: Array<(
    { __typename?: 'FormProductProduct' }
    & Pick<Types.FormProductProduct, 'id' | 'productId' | 'registrationData'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Types.Product, 'id' | 'title' | 'productType'>
    ) }
  )> }
);

export type CategoryDataFragment = (
  { __typename?: 'Product' }
  & Pick<Types.Product, 'id' | 'title' | 'count' | 'productType' | 'description' | 'startDate' | 'startTime' | 'endDate' | 'endTime' | 'data'>
  & { children: Array<(
    { __typename?: 'Product' }
    & Pick<Types.Product, 'id' | 'startDate'>
  )>, parent?: Types.Maybe<(
    { __typename?: 'Product' }
    & Pick<Types.Product, 'id'>
  )> }
);

export const TagsDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TagsData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Tag"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"color"}}]}}]} as unknown as DocumentNode<TagsDataFragment, unknown>;
export const FormDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conferenceId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}}]}}]} as unknown as DocumentNode<FormDataFragment, unknown>;
export const RegistrationDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RegistrationData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Registration"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"participantId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormData"}}]}}]}},...FormDataFragmentDoc.definitions]} as unknown as DocumentNode<RegistrationDataFragment, unknown>;
export const EmailDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EmailData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Email"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"to"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"html"}},{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]} as unknown as DocumentNode<EmailDataFragment, unknown>;
export const PaymentDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PaymentData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Payment"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"paymentState"}},{"kind":"Field","name":{"kind":"Name","value":"paymentType"}},{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]} as unknown as DocumentNode<PaymentDataFragment, unknown>;
export const CategoryDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"productType"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"startTime"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"endTime"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"children"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}}]}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CategoryDataFragment, unknown>;
export const PersonDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PersonData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Person"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"personType"}}]}}]} as unknown as DocumentNode<PersonDataFragment, unknown>;
export const ProductRegDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductRegData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductRegistration"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"formProduct"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"fields"}},{"kind":"Field","name":{"kind":"Name","value":"formId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}},{"kind":"Field","name":{"kind":"Name","value":"shareGroup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"participant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"charges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"product"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"people"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PersonData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}},...CategoryDataFragmentDoc.definitions,...PersonDataFragmentDoc.definitions]} as unknown as DocumentNode<ProductRegDataFragment, unknown>;
export const ChargeDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChargeData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Charge"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"amountCents"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"productType"}},{"kind":"Field","name":{"kind":"Name","value":"due"}},{"kind":"Field","name":{"kind":"Name","value":"clientId"}},{"kind":"Field","name":{"kind":"Name","value":"creditCents"}}]}}]} as unknown as DocumentNode<ChargeDataFragment, unknown>;
export const SelectedProductDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectedProductData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SelectedProduct"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"formProduct"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"prices"}}]}},{"kind":"Field","name":{"kind":"Name","value":"productRegistrations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductRegData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"charges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChargeData"}},{"kind":"Field","name":{"kind":"Name","value":"regPeriod"}},{"kind":"Field","name":{"kind":"Name","value":"client"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"formProduct"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"prices"}}]}}]}}]}},...ProductRegDataFragmentDoc.definitions,...ChargeDataFragmentDoc.definitions]} as unknown as DocumentNode<SelectedProductDataFragment, unknown>;
export const InvoiceDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InvoiceData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Invoice"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"number"}},{"kind":"Field","name":{"kind":"Name","value":"amountCents"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}},{"kind":"Field","name":{"kind":"Name","value":"htmlUrl"}},{"kind":"Field","name":{"kind":"Name","value":"pdfUrl"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"invoiceType"}},{"kind":"Field","name":{"kind":"Name","value":"products"}}]}}]} as unknown as DocumentNode<InvoiceDataFragment, unknown>;
export const ParticipantCountsDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ParticipantCountsData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ParticipantCount"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reviewsAssigned"}},{"kind":"Field","name":{"kind":"Name","value":"reviewsCompleted"}},{"kind":"Field","name":{"kind":"Name","value":"submittedAbstracts"}},{"kind":"Field","name":{"kind":"Name","value":"totalCharges"}},{"kind":"Field","name":{"kind":"Name","value":"totalPayed"}},{"kind":"Field","name":{"kind":"Name","value":"totalDue"}}]}}]} as unknown as DocumentNode<ParticipantCountsDataFragment, unknown>;
export const FormFieldDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormFieldData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"displayType"}},{"kind":"Field","name":{"kind":"Name","value":"fieldType"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"order"}},{"kind":"Field","name":{"kind":"Name","value":"subFields"}}]}}]} as unknown as DocumentNode<FormFieldDataFragment, unknown>;
export const AbstractDisplayerDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AbstractDisplayerData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Abstract"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedFor"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"submitterData"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"body"}},{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"conference"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scienceSettings"}}]}},{"kind":"Field","name":{"kind":"Name","value":"abstractGroup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"body"}}]}}]}}]} as unknown as DocumentNode<AbstractDisplayerDataFragment, unknown>;
export const ConferenceUserDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConferenceUserData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConferenceUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"participantSettings"}},{"kind":"Field","name":{"kind":"Name","value":"reviewerId"}},{"kind":"Field","name":{"kind":"Name","value":"scienceData"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}},{"kind":"Field","name":{"kind":"Name","value":"hasPassword"}},{"kind":"Field","name":{"kind":"Name","value":"participantId"}},{"kind":"Field","name":{"kind":"Name","value":"forms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"registrationData"}},{"kind":"Field","name":{"kind":"Name","value":"hasDebt"}}]}}]} as unknown as DocumentNode<ConferenceUserDataFragment, unknown>;
export const ShortConferenceDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShortConferenceData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}},{"kind":"Field","name":{"kind":"Name","value":"scienceSettings"}},{"kind":"Field","name":{"kind":"Name","value":"forms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}}]}}]}}]} as unknown as DocumentNode<ShortConferenceDataFragment, unknown>;
export const ClientBaseDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientBaseData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Client"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<ClientBaseDataFragment, unknown>;
export const ClientShowDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientShowData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Client"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ClientBaseData"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"idNumber"}},{"kind":"Field","name":{"kind":"Name","value":"accountBookData"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}}]}},...ClientBaseDataFragmentDoc.definitions]} as unknown as DocumentNode<ClientShowDataFragment, unknown>;
export const FormCategoryDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormCategoryData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormProduct"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"ord"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"prices"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"extraCharges"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"hasPartner"}},{"kind":"Field","name":{"kind":"Name","value":"fields"}},{"kind":"Field","name":{"kind":"Name","value":"ord"}},{"kind":"Field","name":{"kind":"Name","value":"showIf"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"formProductProducts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"productId"}},{"kind":"Field","name":{"kind":"Name","value":"registrationData"}},{"kind":"Field","name":{"kind":"Name","value":"product"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"productType"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"modifierOnly"}},{"kind":"Field","name":{"kind":"Name","value":"participantCount"}}]}}]} as unknown as DocumentNode<FormCategoryDataFragment, unknown>;